<template>
  <div>
    <b-form-group :label="label"
                  label-cols="3"
                  class="flat form-group--small">
      <multiselect v-model="operator"
                   class="multiselect--small"
                   label="text"
                   track-by="value"
                   :placeholder="operatorPlaceholder ? operatorPlaceholder : $t('options.select_option')"
                   :options="operatorOptions"
                   :multiple="operatorMultiple"
                   @input="applyFilter"
                   >
      </multiselect>
    </b-form-group>
    <b-form-group>
      <multiselect v-model="value"
                   :label="optionsLabel"
                   :track-by="optionsTrackBy"
                   :placeholder="valuePlaceholder ? valuePlaceholder : $t('options.select_option')"
                   :options="valueOptions"
                   :multiple="valueMultiple"
                   @input="applyFilter">
      </multiselect>
    </b-form-group>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "SelectWithOperator",
  props: [
    'filterName', 'filterQuery', 'label',
    'operatorMultiple', 'operatorOptions', 'operatorPlaceholder', 'defaultOperator', 'storeData',
    'valueMultiple', 'valueOptions', 'valuePlaceholder', 'defaultValue', 'optionsTrackBy', 'optionsLabel'
  ],
  components: {Multiselect},
  data() {
    return {
      operator: this.defaultOperator,
      value: this.defaultValue,
    };
  },
  mounted() {
    this.$root.$on('resetFilters', () => {
      this.operator = this.defaultOperator;
      this.value = this.defaultValue;
    })

    this.checkStoreData();
  },
  methods: {
    checkStoreData() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.storeData && this.storeData.hasOwnProperty(this.filterName)) {
        if (this.storeData[this.filterName]) {
          this.value = this.storeData[this.filterName].value;
          this.operator = this.storeData[this.filterName].operator;
          this.applyFilter();
        }
      }
    },
    applyFilter() {
      if(!this.operator) {
        this.operator = this.defaultOperator;
      }

      if (this.valueMultiple === true && this.value && this.value.length > 0) {
        if (this.value && this.value.length > 0) {
          let filter = '';
          let values = '';
          this.value.forEach( (value,i) => {
            values += value[this.optionsTrackBy];
            if(i !== this.value.length - 1){
              values += ',';
            }
          });
          filter += `&filters[${this.filterName}][0][value]=${values}`;
          filter += `&filters[${this.filterName}][0][operator]=${ this.operator ? this.operator.value : 'in'}`;
          this.filterQuery[this.filterName] = filter;
        } else {
          delete this.filterQuery[this.filterName];
          this.$emit('store', {filterName: this.filterName, value: null});
        }
      } else if (this.value && this.value.length > 0) {
        this.filterQuery[this.filterName] = '&filters[' + this.filterName + '][0][value]=' + this.value[this.optionsTrackBy];
        this.$emit('store', {
          filterName: this.filterName,
          operator: this.operator,
          value: this.value
        });
      } else {
        if (this.value === null) {
          delete this.filterQuery[this.filterName];
          this.$emit('store', {filterName: this.filterName, value: null});
        } else {
          this.filterQuery[this.filterName] = '&filters[' + this.filterName + '][0][value]=' + this.value[this.optionsTrackBy];
          this.filterQuery[this.filterName] += `&filters[${this.filterName}][0][operator]=${ this.operator ? this.operator.value : 'eq' }`;
          this.$emit('store', {
            filterName: this.filterName,
            operator: this.operator,
            value: this.value
          });
        }
      }
    }
  }
}
</script>
