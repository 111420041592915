
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class TranslationsApp extends Vue {

  mounted() {
    this.$store.dispatch('root/getLocale');
  }

}
