<template>
  <b-modal
      ref="create-translation-label-modal"
      size="xl"
  >
    <template #modal-header>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h4>{{ $t('translations.add_new_label.title') }}</h4>
      </div>
    </template>
    <b-form-group
        :label="$t('translations.label_name')"
        label-for="label-name"
    >
      <b-form-input
          v-model="labelName"
          @input="$v.labelName.$model = $event"
          :readonly="step === 2"
          @keypress="isRestricted($event)"
          :state="!$v.labelName.$dirty ? null :(!$v.labelName.$error)"
          trim></b-form-input>
      <p v-if="$v.labelName.$dirty && !$v.labelName.required" class="text-danger">
        {{ $t('translations.errors.required') }}</p>
    </b-form-group>
    <b-alert v-if="cantCreate" variant="danger" show>{{ $t('translations.errors.label_exist') }}</b-alert>
    <b-form-group v-if="step === 2" :label="$t('translations.choose.domains')" v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectedDomainGroups"
          :options="domainGroups"
          :state="!$v.selectedDomainGroups.$dirty ? null :(!$v.selectedDomainGroups.$error)"
          :aria-describedby="ariaDescribedby"
          value-field="id"
          text-field="name"
      >
      </b-form-checkbox-group>
    </b-form-group>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button type="button" class="mr-1" variant="secondary" @click="hideModal">
          {{ $t('translations.button.cancel') }}
        </b-button>
        <b-button v-if="step === 1" variant="primary" @click="nextStep" :disabled="loading">
            <span v-if="loading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('translations.button.loading') }}
            </span>
          <span v-else>
              {{ $t('translations.button.next') }}
            </span>
        </b-button>
        <b-button v-if="step === 2" type="button" class="mr-1" variant="danger" @click="goToFirstStep">
          {{ $t('translations.button.back') }}
        </b-button>
        <b-button v-if="step === 2" type="button" class="mr-1" variant="success" @click="saveTranslationLabel">
            <span v-if="loading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('translations.button.loading') }}
            </span>
          <span v-else>
              {{ $t('translations.button.save') }}
            </span>
        </b-button>
        <b-button v-if="step === 2" type="button" class="mr-1" variant="success" @click="saveAndTranslate">
              <span v-if="loading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('translations.button.loading') }}
            </span>
          <span v-else>
              {{ $t('translations.button.save_and_translate') }}
            </span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      labelName: '',
      selectedDomainGroups: [],
      step: 1,
      cantCreate: false,
      loading: false,
      errors: {
        required: 'This field is required!'
      }
    }
  },
  validations: {
    labelName: {
      required
    },
    selectedDomainGroups: {
      required
    }
  },
  mounted() {
    this.openModal();
    // this.selectedDomainGroups = this.domainGroups.map(dd => dd.id);
  },
  computed: {
    domainGroups: function () {
      return this.$store.getters["translations/createLabelDomainGroups"];
    },
  },
  methods: {
    saveTranslationLabel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.loading = true;
      this.$store.dispatch('translations/postLabel', this.labelName.replaceAll(' ', ''))
          .then(d => {
            const gr = this.selectedDomainGroups.map(dd => {
              return {id: dd};
            });

            this.$store.dispatch('translations/postLabelGroup', {id: d.id, payload: gr}).then(
                () => {
                  this.clearForm();
                  this.$emit('refresh');
                  this.hideModal();
                }
            )
          })
    },
    saveAndTranslate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.loading = true;
      this.$store.dispatch('translations/postLabel', this.labelName.replaceAll(' ', ''))
          .then(d => {
            const gr = this.selectedDomainGroups.map(dd => {
              return {id: dd};
            });

            this.$store.dispatch('translations/postLabelGroup', {id: d.id, payload: gr}).then(
                () => {
                  this.$router.push({name: 'translations-details', params: {id: this.labelName.replaceAll(' ', '')}});
                  this.clearForm();
                  this.$emit('refresh');
                  this.hideModal();
                }
            )
          })
    },
    nextStep() {
      this.$v.$touch();
      if (!this.$v.labelName.$error) {
        this.loading = true;
        this.$store.dispatch('translations/checkLabel', this.labelName.replaceAll(' ', '')).then(() => {
          if (this.domainGroups.length) {
            this.step = 2;
            this.cantCreate = false;
          } else {
            this.cantCreate = true;
          }
          this.loading = false;
          this.$v.reset();
        })

      }
    },
    goToFirstStep() {
      this.step = 1;
    },
    clearForm() {
      this.selectedDomainGroups = [];
      this.labelName = '';
      this.loading = false;
    },
    openModal() {
      this.resetProperties();
      this.$refs['create-translation-label-modal'].show();
    },
    hideModal() {
      this.$refs['create-translation-label-modal'].hide();
    },
    resetProperties() {
      this.labelName = '';
      this.step = 1;
      this.$v.$reset();
    },
    isRestricted: function (e) {
      e = (e) ? e : window.event;
      let charCode = (e.which) ? e.which : e.keyCode;
      // Space not allowed
      if (charCode === 32) {
        e.preventDefault();
      } else {
        return true;
      }
    }
  },
  watch: {
  }
}
</script>
