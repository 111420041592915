
import {Component} from 'vue-property-decorator';
import PageCard from "@/components/page/PageCard.vue";
import PageHeader from "@/components/page/PageHeader.vue";
import CreateTranslationLabelModal from "@/modules/translations/components/CreateTranslationLabelModal.vue";
import UpdateTranslationFiles from "@/modules/translations/components/UpdateTranslationFiles.vue";
import ExportLabelModal from "@/modules/translations/components/ExportLabelModal.vue";
import ImportLabelModal from "@/modules/translations/components/ImportLabelModal.vue";
import FilterInput from "@/components/grid/filters/FilterInput.vue";
import FilterSelect from "@/components/grid/filters/FilterSelect.vue";
import FilterSelectWithOperator from "@/components/grid/filters/FilterSelectWithOperator.vue";
import {mixins} from "vue-class-component";
import GridMixin from '@/components/grid/GridMixin';
import GridPagination from "@/components/grid/grid-pagination/GridPagination.vue";
import Vue from "vue";

@Component({
  components: {
    PageCard,
    PageHeader,
    CreateTranslationLabelModal,
    FilterInput,
    FilterSelect,
    FilterSelectWithOperator,
    GridPagination
  },
  mixins: [GridMixin]
})
export default class TranslationsGrid extends mixins<any>(GridMixin) {

  gridGetUrl: string = '/translation-row';
  orderColumn: string | null = null;
  perPage: number = 10;
  currentPage: number = 1;
  fields: any[] = [
    {key: 'key', label: this.$t('table.header.label_key')},
    {key: 'domainGroups', label: this.$t('table.header.domain_groups')},
    {key: 'locale', label: this.$t('table.header.locale')},
    {key: 'actions', label: this.$t('table.header.actions') }
  ];
  items: any[] = []

  containOperator: any[] = [
    {value: 'contain', text: this.$t('operator.contains')},
    {value: 'notContain', text: this.$t('operator.not_contains')},
  ]

  mounted() {
    this.$store.dispatch('translations/getDomainGroups');

    if (!this.filters) {
      this.initFilters();
    }
  }

  storeFilter(data: any) {
    this.$store.commit('root/setFilter', {storeName: 'translations', ...data})
  }

  initFilters() {
    this.$store.commit('root/initFilters', {
      storeName: 'translations',
      filterNames: ['key', 'phrase', 'locales', 'domainGroups']
    });
  }

  get locale() {
    const key: any = 'label';
    const groups: any = [process.env.VUE_APP_ADMIN_GROUP_NAME, process.env.VUE_APP_FRONT_GROUP_NAME];
    const locales = this.$store.getters["root/locale"]
        .filter((loc: any) => groups.indexOf(loc.domainGroup) > -1);

    return [...new Map(locales.map((item: any) =>
        [item[key], item])).values()];
  }

  get filters() {
    return this.$store.getters["root/filter"]('translations');
  }

  get domainGroups() {
    return this.$store.getters["translations/domainGroups"].map((o: any) => {
      return {text: o.name, value: o.id}
    });
  }

  editItem(item: any) {
    this.$router.push({name: 'translations-details', params: {id: item.key}});
  }

  deleteItem(item: any) {

    this.$bvModal.msgBoxConfirm('Label: ' + item.key, {
      title: 'Are you sure to delete label?',
      okTitle: 'Delete',
      cancelTitle: 'Cancel',
      okVariant: 'danger',
    })
        .then(value => {
          if (value) {
            this.$store.dispatch('translations/deleteLabel', item.key)
                .then(() => {
                  // @ts-ignore
                  this.loadItems();
                })
          }
        });
  }

  importLabels() {

    const
        ModalConstructor = Vue.extend(ImportLabelModal),
        modal = new ModalConstructor({parent: this});

    modal.$mount();

  }

  exportLabels() {

    const
        ModalConstructor = Vue.extend(ExportLabelModal),
        modal = new ModalConstructor({parent: this});

    modal.$mount();

  }

  createLabel() {
    const
        ModalConstructor = Vue.extend(CreateTranslationLabelModal),
        modal = new ModalConstructor({parent: this});

    modal.$mount();
  }

  generateFiles() {
    const
        ModalConstructor = Vue.extend(UpdateTranslationFiles),
        modal = new ModalConstructor({parent: this});

    modal.$mount();
  }
}
