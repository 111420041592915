<template>
  <b-modal
      ref="add-coins-modal"
      size="lg"
      :title="$t('translations.update_files.title')"
  >
    <div>
      <h6 class="w-100 mb-4">{{$t('translations.update_files.subtitle')}}</h6>
      <table class="table table-sm table-striped table-bordered table-hover">
        <thead class="thead-dark">
        <tr>
          <th>{{$t('translations.table.domain_groups')}}</th>
          <th>{{$t('translations.table.updated_at')}}</th>
          <th>{{$t('translations.table.update_status')}}</th>
          <th>{{$t('translations.table.actions')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="group in groups" :key="group.id">
          <td>{{ group.domainGroupName }}</td>
          <td>{{ group.updatedAt | dateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
          <td>
            <span class="text-green" v-if="group.synced">true</span>
            <span class="text-red" v-else>false</span>
          </td>
          <td>
            <b-button variant="primary" size="xs" v-if="!group.synced" @click="generateTranslation(group.id)">{{$t('btn.update')}}</b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <template #modal-footer>
      <b-button @click="hideModal">{{$t('btn.close')}}</b-button>
    </template>
  </b-modal>
</template>

<script>

import alertify from "alertifyjs";

export default {
  data() {
    return {
      groups: []
    }
  },
  validations: {},
  mounted() {
    this.openModal();
    this.getGrid();
  },
  methods: {
    getGrid() {
      this.$store.dispatch('translations/getGeneratorGrid')
          .then(
              data => {
                this.groups = data.results;
              }
          )
    },
    generateTranslation(id) {
      this.$store.dispatch('translations/generateTranslation', id)
      .then(
          () => {
            this.getGrid();
            alertify.success('Generate success.');
          },
          (e) => {
            alertify.error(this.$t(e.response.data.label));
          }
      )
    },
    openModal() {
      this.$refs['add-coins-modal'].show();
    },
    hideModal() {
      this.$refs['add-coins-modal'].hide();
    }
  }
}
</script>
