<template>
  <b-modal
      ref="export-labels-modal"
      size="lg"
      :title="$t('translations.export_files.title')"
  >
    <div>
      <b-form-group :label="$t('export.labels.choose.locale')">
        <multiselect
            v-model="tmpLocale"
            label="label"
            track-by="id"
            value="id"
            :options="locales"
            :state="!$v.form.locale.$dirty ? null :(!$v.form.locale.$error)"
            @input="applyLocale"
        >
        </multiselect>
        <p v-if="$v.form.locale.$dirty && !$v.form.locale.required" class="text-danger">{{
            $t('export.errors.required')
          }}</p>
      </b-form-group>
      <b-form-group :label="$t('export.labels.choose.domains')" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="form.domainGroups"
            :options="domainGroups"
            :state="!$v.form.domainGroups.$dirty ? null :(!$v.form.domainGroups.$error)"
            :aria-describedby="ariaDescribedby"
            value-field="id"
            text-field="name"
        >
        </b-form-checkbox-group>
        <p v-if="$v.form.domainGroups.$dirty && !$v.form.domainGroups.required" class="text-danger">
          {{ $t('export.errors.required') }}</p>
      </b-form-group>
    </div>
    <template #modal-footer>
      <b-button @click="hideModal" class="mr-2">Close</b-button>
      <b-button variant="primary" :disabled="busy" @click="submit">
        <div v-if="busy">
          <b-spinner small type="grow"></b-spinner>
          {{ $t('btn.state.exporting') }}
        </div>
        <div v-else>
          {{ $t('btn.export') }}
        </div>
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import Multiselect from "vue-multiselect";
import {required} from "vuelidate/lib/validators";

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      tmpLocale: null,
      form: {
        domainGroups: [],
        locale: null
      }
    }
  },
  validations: {
    form: {
      domainGroups: {
        required
      },
      locale: {
        required
      }
    }
  },
  mounted() {
    this.openModal();
  },
  computed: {
    locales: function () {
      const key = 'label';
      const groups = [process.env.VUE_APP_ADMIN_GROUP_NAME, process.env.VUE_APP_FRONT_GROUP_NAME];
      const locales = this.$store.getters["root/locale"]
          .filter((loc) => groups.indexOf(loc.applicationName) > -1);

      return [...new Map(locales.map((item) =>
          [item[key], item])).values()];
    },
    busy: function () {
      return this.$store.getters["translations/isBusy"];
    },
    domainGroups: function () {
      return this.$store.getters["translations/domainGroups"];
    }
  },
  methods: {
    applyLocale(value) {
      this.form.locale = value.id;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch('translations/exportLabels', this.form)
            .then((response) => {
              let url = window.URL.createObjectURL(new Blob([response.data]));
              let pom = document.createElement('a');
              pom.setAttribute('href', url)
              pom.setAttribute('download', `labels-export-${new Date().getTime()}.csv`);
              pom.click(); // this will download the file
              this.hideModal();
            })
      }

    },
    openModal() {
      this.$refs['export-labels-modal'].show();
    },
    hideModal() {
      this.$refs['export-labels-modal'].hide();
    }
  }
}
</script>
