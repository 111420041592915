<template>
  <b-modal
      ref="export-labels-modal"
      size="lg"
      :title="$t('translations.import_files.title')"
  >
    <div>
      <b-form-group :label="$t('import.labels.choose.locale')">
        <multiselect
            :disabled="!!importLog"
            v-model="tmpLocale"
            label="label"
            track-by="id"
            value="id"
            :placeholder="$t('options.select_option')"
            :options="locales"
            :state="!$v.form.locale.$dirty ? null :(!$v.form.locale.$error)"
            @input="applyLocale"
        >
        </multiselect>
        <p v-if="$v.form.locale.$dirty && !$v.form.locale.required" class="text-danger">
          {{$t('import.errors.required')}}
        </p>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
            id="checkbox-1"
            v-model="form.createKeys"
            name="checkbox-1"
            :disabled="!!importLog"
            :value="true"
            :unchecked-value="false"
        >
          {{$t('import.create_empty_labels')}}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <b-form-file
            v-model="form.file"
            :state="!$v.form.file.$dirty ? null :(!$v.form.file.$error)"
            :placeholder="$t('import.file.choose_label')"
            :drop-placeholder="$t('import.file.drop_placeholder')"
            :browse-text="$t('import.file.browse_text')"
            accept=".csv"
            :disabled="!!importLog"
        ></b-form-file>
        <p v-if="$v.form.file.$dirty && !$v.form.file.required" class="text-danger">
          {{$t('import.errors.required')}}
        </p>
      </b-form-group>

      <b-form-group v-if="importLog" :label="$t('import.label.import_log')">
        <b-form-textarea
            id="textarea"
            readonly
            v-model="importLog"
            rows="3"
            max-rows="6"
        >
        </b-form-textarea>
        <p v-if="$v.form.locale.$dirty && !$v.form.locale.required" class="text-danger">
          {{$t('import.errors.required')}}
        </p>
      </b-form-group>
    </div>
    <template #modal-footer>
      <b-button @click="hideModal" class="mr-2">{{ $t('btn.close') }}</b-button>
      <b-button variant="primary" :disabled="busy" v-if="!importLog" @click="submit">
        <div v-if="busy">
          <b-spinner small type="grow"></b-spinner>
          {{ $t('btn.state.importing') }}
        </div>
        <div v-else>
          {{ $t('btn.import') }}
        </div>
      </b-button>
    </template>
    {{form}}
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import {required} from "vuelidate/lib/validators";

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      tmpLocale: null,
      importLog: null,
      form: {
        createKeys: true,
        locale: null,
        file: null
      }
    }
  },
  validations: {
    form: {
      locale: {
        required
      },
      file: {
        required
      }
    }
  },
  mounted() {
    this.openModal();
  },
  computed: {
    locales: function () {
      const key = 'label';
      const groups = [process.env.VUE_APP_ADMIN_GROUP_NAME, process.env.VUE_APP_FRONT_GROUP_NAME];
      const locales = this.$store.getters["root/locale"]
          .filter((loc) => groups.indexOf(loc.applicationName) > -1);

      return [...new Map(locales.map((item) =>
          [item[key], item])).values()];
    },
    busy: function () {
      return this.$store.getters["translations/isBusy"];
    },
    domainGroups: function () {
      return this.$store.getters["translations/domainGroups"];
    }
  },
  methods: {
    applyLocale(value) {
      this.form.locale = value.id;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch('translations/importLabels', this.form)
            .then((resp) => {
              if (resp.data && resp.data['importLog'] && resp.data.importLog.length) {
                this.importLog = resp.data.importLog.join('\n');
              }
              this.$parent.loadItems();
            })
      }
    },
    openModal() {
      this.$refs['export-labels-modal'].show();
    },
    hideModal() {
      this.$refs['export-labels-modal'].hide();
    }
  }
}
</script>
